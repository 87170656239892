import { Component, Input,Output, EventEmitter, OnInit } from '@angular/core';

@Component({
  selector: 'app-micmp',
  templateUrl: './micmp.component.html',
  styleUrls: ['./micmp.component.css']
})
export class MicmpComponent implements OnInit {
  @Input() name="eo";
  @Output() nuevoNombre= new EventEmitter();
  
  
  constructor() { }

  ngOnInit(): void {
  }
  addNuevoNombre(){
    this.nuevoNombre.emit(this.name);
  }

}
