import { Component } from '@angular/core';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent {
  title = 'miapp';
  nombre="mi nombre es hola";
  nombre1="nombre1";
  nombre2="";
  damelahora(){
    this.nombre="esta es mi hora";
  }
}
